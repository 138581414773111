export const routes = [
    {
        path: '/customers',
        name: 'customers.browse',
        component: () => import(/* webpackChunkName: "CustomersBrowse" */ '@/views/app/CustomersBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/customers/:id/clone',
        name: 'customers.clone',
        component: () => import(/* webpackChunkName: "CustomersActions" */ '@/views/app/CustomersActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/customers/create',
        name: 'customers.create',
        component: () => import(/* webpackChunkName: "CustomersActions" */ '@/views/app/CustomersActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/customers/:id/edit',
        name: 'customers.edit',
        component: () => import(/* webpackChunkName: "CustomersActions" */ '@/views/app/CustomersActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/customers/:id/delete',
        name: 'customers.delete',
        component: () => import(/* webpackChunkName: "CustomersActions" */ '@/views/app/CustomersActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/customers/:id/show',
        name: 'customers.show',
        component: () => import(/* webpackChunkName: "CustomersActions" */ '@/views/app/CustomersActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]